<template>
  <div class="members">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <NavBar />
    <IntroduceEMG />
    <Members />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import IntroduceEMG from "@/components/IntroduceEMG.vue";
import Members from "@/components/Members.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Product",
  components: {
    NavBar,
    IntroduceEMG,
    Members,
    Footer,
  },
};
</script>