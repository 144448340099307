<template>
  <!--====== TEAM PART START ======-->

  <section id="members" class="team-area pt-20 pb-0 gray-bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="banner-content">
            
            <div class="mb-40 member-display slide-right">
              <img
                class="member-layout-img"
                src="../assets/images/lab/lab3.jpeg"
                alt=""
              />
              <img
                class="member-layout-img"
                src="../assets/images/lab/lab4.jpeg"
                alt=""
              />
              <img
                class="member-layout-img"
                src="../assets/images/lab/lab1.jpeg"
                alt=""
              />
              <img
                class="member-layout-img"
                src="../assets/images/lab/lab2.jpeg"
                alt=""
              />
              <img
                class="member-layout-img"
                src="../assets/images/lab/lab5.jpeg"
                alt=""
              />
              <img
                class="member-layout-img"
                src="../assets/images/lab/lab6.jpeg"
                alt=""
              />
              <img
                class="member-layout-img"
                src="../assets/images/lab/lab7.jpeg"
                alt=""
              />
            </div>

            <div class="section-title">
              <h5 class="sub-title">Thành viên chủ chốt</h5>
              <div class="btn-group" role="group" aria-label="Basic example">
              </div>
            </div>
          </div>
          <!-- section title -->
        </div>
      </div>
      <!-- row -->
      <div class="container-fluif">
        <div class="row project-active">
          <div class="row">
            <div
              class="col-lg-3 col-md-6 col-sm-6"
              v-for="(member, index) in members"
              :key="index"
            >
              <div
                class="single-team text-center mt-0 wow fadeInUp mt-3"
                data-wow-duration="1.5s"
                data-wow-delay="0.4s"
              >
                <div class="team-image">
                  <img v-bind:src="member.linkImage" alt="Team" />
                </div>
                <div class="team-content">
                  <h4 class="team-name">
                    <a>{{ member.fullName }} </a>
                  </h4>
                  <span class="sub-title">{{ member.position }}</span>
                </div>
              </div>
              <!-- single team -->
            </div>

            <div class="section-title mt-40">
              <h5 class="sub-title">
                Thành viên, Cộng tác viên, Cựu thành viên
              </h5>
            </div>
            <div
              class="col-lg-3 col-md-6 col-sm-6"
              v-for="(oldMember, index) in oldMembers"
              :key="index"
            >
              <div
                class="single-team text-center mt-0 wow fadeInUp mt-3"
                data-wow-duration="1.5s"
                data-wow-delay="0.4s"
              >
                <div class="team-image">
                  <img v-bind:src="oldMember.linkImage" alt="Team" />
                </div>
                <div class="team-content">
                  <h4 class="team-name">
                    <a>{{ oldMember.fullName }} </a>
                  </h4>
                  <span class="sub-title">{{ oldMember.position }}</span>
                </div>
              </div>
              <!-- single team -->
            </div>
            <!-- <div class="member-space"></div> -->
          </div>
        </div>
        <!-- row -->
      </div>
    </div>
    <!-- container -->
  </section>
</template>

<script>
import { members, oldmembers } from "../firebase-config";
// import { members } from "../firebase-config";
export default {
  data() {
    return {
      isActive: true,
      activeMemberNow: "",
      activeMemberOld: "",
      members: [],
      oldMembers: [],
    };
  },
  methods: {
    fetchDataMember() {
      this.activeMemberNow = " btn-danger";
      this.activeMemberOld = " btn-light";
      this.members = [];
      this.check = true;
      members.get().then((res) => {
        this.members = res.docs.map((doc) => {
          console.log(doc.data());
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
      });
    },
    fetchDataOldMember() {
      this.activeMemberNow = " btn-light";
      this.activeMemberOld = " btn-danger";
      this.members = [];
      this.check = false;
      oldmembers.get().then((res) => {
        this.oldMembers = res.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
      });
    },
  },
  created() {
    this.fetchDataMember();
    this.fetchDataOldMember()
  },
};
</script>

<style>
.member-display {
  display: flex;
}
.member-layout-img {
  height: 300px !important;
  width: auto;
  margin: 8px;
}

.slide-right {
  position: relative;
  animation: mymove 60s infinite;
}

@keyframes mymove {
  0% {
    left: 0px;
  }

  /* 50% {left: 100%;} */

  50% {
    left: -2000px;
  }

  100% {
    left: 0px;
  }
}

/* .slide-right {
   width: 100%;
   overflow: hidden;
   margin-left: 0px;
   max-width: 5000px
}

.slide-right img {
   animation: 2s slide-right;
   animation-delay: 2s;
}

@keyframes slide-right {
    from {
       margin-left: -500px;
    }

    to {
       margin-left: 0%;
    }
 } */
</style>